<template>
  <div>
    <v-card flat>
      <v-card-title class="align-end">
        <v-text-field
          style="max-width: 400px;"
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          clearable
          dense
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
      </v-card-title>
      <DialogSalesDuplicadas
        v-if="dialogSalesDuplicadas"
        :dialogSalesDuplicadas.sync="dialogSalesDuplicadas"
        :item="sale"
        @fecth-duplicadas="fetchSalesDuplicadas"
      />
      <v-divider></v-divider>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :search="search"
          :items="sales_duplicadas"
          :loading="loading"
          :items-per-page="10"
          class="data-tables data-tables__row-click"
        >
          <template v-slot:item.date_payment="{ item }">
            {{ item.date_payment | dateFormat("dd/MM/yyyy") }}
          </template>
          <template v-slot:item.status="{ item }">
            {{ item.status === 0 ? "Pendente" : "Cadastrado" }}
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn
              v-if="item.status === 0"
              icon
              @click.stop="cadastroSale(item)"
            >
              <v-icon>mdi-account-plus-outline</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.client_cel="{ item }">
            <div class="d-flex align-center justify-start">
              <span>{{ item.client_cel }}</span>

              <v-btn x-small icon class="ml-1" v-if="item.client_cel">
                <v-icon small @click.stop="goToWhats(item.client_cel)" color="green">
                  mdi-whatsapp
                </v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { allDuplicadas } from "@/api/admin/eduzz.js";
export default {
  name: "SalesDuplicadas",

  data() {
    return {
      sales_duplicadas: [],
      loading: false,
      search: "",
      sale: {},
      dialogSalesDuplicadas: false,
    };
  },

  components: {
    DialogSalesDuplicadas: () => import("./DialogSalesDuplicadas.vue"),
  },

  computed: {
    headers() {
      return [
        {
          text: "sale_id",
          value: "sale_id",
        },

        {
          text: "Pagamento",
          value: "date_payment",
        },
        {
          text: "Total",
          value: "sale_total",
        },
        {
          text: "Nome",
          value: "client_name",
        },
        {
          text: "Email",
          value: "client_email",
        },
        {
          text: "Documento",
          value: "client_document",
        },
        {
          text: "Telefone",
          value: "client_cel",
           sortable: false,
        },
        {
          text: "Lote",
          value: "content_title",
        },
        {
          text: "VIP",
          value: "vip",
        },
        {
          text: "status",
          value: "status",
        },
        {
          text: "",
          value: "action",
        },
      ];
    },
  },

  methods: {
    goToWhats(telefone) {
      let url = "https://api.whatsapp.com/send?phone=";

      let telefone_sanatizado = telefone.replace(/\D/g, "");

      if (telefone_sanatizado.substr(0, 3) == "595") {
        url = url + telefone_sanatizado;
        window.open(url, "_blank");
      } else if (telefone_sanatizado.substr(0, 2) == "55") {
        url = url + telefone_sanatizado;
        window.open(url, "_blank");
      } else {
        url = url + "55" + telefone_sanatizado;
        window.open(url, "_blank");
      }
    },
    cadastroSale(item) {
      this.dialogSalesDuplicadas = true;
      console.log(item);
      this.sale = item;
    },
    async fetchSalesDuplicadas() {
      this.loading = true;
      await allDuplicadas()
        .then((response) => {
          this.sales_duplicadas = response;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },

  async mounted() {
    this.loading = true;
    await this.fetchSalesDuplicadas();
    this.loading = false;
  },
};
</script>

<style></style>
